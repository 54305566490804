<script setup lang="ts">
import type { PropType } from "vue";
import type { TSvgName } from "~/lib/svg";

const { text, decoratedPhrase, prependIcon, appendIcon } = defineProps({
    text: { type: String, default: "" },
    className: { type: String, default: "" },
    decoratedPhrase: { type: String, default: "" },
    prependIcon: { type: String as PropType<TSvgName | undefined>, default: undefined },
    appendIcon: { type: String as PropType<TSvgName | undefined>, default: undefined },
    appendIconSize: { type: String, default: "1em" },
    prependIconSize: { type: String, default: "1em" },
    appendIconPosition: { type: String, default: "0 0" },
    prependIconPosition: { type: String, default: "0 0" },
    appendIconAnimationOrigin: { type: String, default: "center" },
    prependIconAnimationOrigin: { type: String, default: "center" },
    prependIconAnimationClass: { type: String, default: "" },
    appendIconAnimationClass: { type: String, default: "" },
    dark: Boolean
});
const html = computed(() => {
    if (!text) return "";

    const html = addSpanToEachWord(text);
    if (!decoratedPhrase) return html;

    const phraseHtml = addSpanToEachWord(decoratedPhrase);
    const phraseHtmlWithClass = phraseHtml.replaceAll(/<span>/gi, '<span class="decorated">');

    return html.replaceAll(phraseHtml, phraseHtmlWithClass);
});

function addSpanToEachWord(text: string) {
    return text
        .split(" ")
        .map((w) => (w.length > 1 ? `<span>${w} </span>` : `<span><span class="text-lowercase">${w} </span></span>`))
        .join("");
}
</script>
<template>
    <div :class="`text-h6 text-sm-h5 text-md-h4 text-lg-h3 ${className}`">
        <span class="title-wrapper text-capitalize">
            <Svg
                v-if="prependIcon"
                :class="['prepend', prependIconAnimationClass]"
                :name="prependIcon"
                :width="prependIconSize"
                :height="prependIconSize"
                :style="{ translate: prependIconPosition, transformOrigin: prependIconAnimationOrigin }"
            ></Svg>
            <span :class="{ 'text-purple-dark': !dark, 'text-purple-light': dark, dark: dark }" v-html="html"></span>
            <Svg
                v-if="appendIcon"
                :class="['append', appendIconAnimationClass]"
                :name="appendIcon"
                :width="appendIconSize"
                :height="appendIconSize"
                :style="{ translate: appendIconPosition, transformOrigin: appendIconAnimationOrigin }"
            ></Svg>
        </span>
    </div>
</template>
<style lang="scss" scoped>
:deep(span.decorated) {
    color: rgb(var(--v-theme-text-red));
    background: linear-gradient(transparent 75%, rgba(var(--v-theme-purple-light), 1) 75%) no-repeat;
}

:deep(span.dark span.decorated) {
    background: linear-gradient(transparent 75%, rgba(var(--v-theme-purple-light), 0.2) 75%) no-repeat;
}

.title-wrapper {
    position: relative;

    svg {
        position: absolute;

        &.prepend {
            left: 0;
            top: 0;
            translate: -90% -50%;
        }

        &.append {
            right: 0;
            bottom: 0;
            translate: 100% 50%;
        }
    }
}
</style>
